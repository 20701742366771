
<template>
  <div id="index">
    <div class="bg">
      <div class="top">
        <dv-decoration-8 style="width: 20%; height: 50px" />
        <dv-decoration-10 style="width: 60%; height: 32px" />
        <!-- <dv-decoration-10 style="width:90%;height:5px;" /> -->
        <dv-decoration-8 :reverse="true" style="width: 20%; height: 50px" />
      </div>
      <div class="title">
        <dv-decoration-6
          style="
            margin-right: 20px;
            width: 100px;
            height: 30px;
            padding-top: 11px;
          "
        />
        <span class="title-text">现场评审数据中心</span>
        <dv-decoration-6
          style="
            width: 100px;
            height: 30px;
            margin-left: 20px;
            padding-top: 11px;
          "
        />
      </div>
      <div class="search" :class="{ toHeight: isShow }" ref="navUl">
        项目分组：
        <div
          v-for="item in classify.slice(0, classify.length)"
          :key="item.stagegroupName"
          class="compute"
          :class="{ active: active == item.stagegroupName }"
          @click="selected(item.stagegroupName)"
        >
          {{ item.stagegroupName }}
        </div>
      </div>
      <div class="more" v-if=" w>sw">
        <div class="morebt">
          <i
            v-if="!isShow"
            class="el-icon-arrow-down"
            style="font-size: xx-large"
            @click="morebt"
          ></i>
          <i
            v-else
            class="el-icon-arrow-up"
            style="font-size: xx-large"
            @click="morebt"
          ></i>
        </div>
      </div>
      <div class="introduce" ref="introduce">
        <div class="border"></div>
        <div style="padding-left: 10px;font-size: 35px;line-height: 1.75;">
          {{stageName}} — ({{active}})
        </div>
      </div>

      <div class="chart">
        <div class="right">
          <div class="table-header">
            <div style="width:130px;text-align: center">排名</div>
            <div style="width:300px;text-align: center;padding-left: 5.5%;">项目名称</div>
            <div style="flex-grow: 1;text-align: center;padding-left: 4.5%;">评审状态</div>
            <div style="flex-grow: 1;text-align: center">最终得分</div>
          </div>
          <dv-scroll-board
            :config="config"
            :style='style1'
          />
        </div>
        <div class="left">
          <div class="top_bottom public animate__animated " :class="{animate__flipInX: isAnimate==true}">
            <div class="min-title">
              <span>当前在评项目</span>
            </div>
            <div style="margin-top: 22px; width: 100%; height: 50%" v-if="stagelivePartNumber!==null">
              <dv-decoration-11 style="width: 100%; height:100%;text-overflow: ellipsis; overflow: hidden;">
                
                <span class="xmname">
                  <span style="font-family: 楷体, KaiTi_GB2312;background-color: rgb(229 103 43);
                   display: inline-block;padding: 0 12px; border-radius: 25px;border: 2px solid; font-size: 28px; font-weight: 600;">{{contprojRegion}}</span> {{currentProjectname}}
                   </span
                ></dv-decoration-11
              >
            </div>
            <div v-else style="    height: 100%;text-align: center;line-height: 5; font-size: 50px;">暂无在评项目 </div>
            <div v-if="stagelivePartNumber==0" style="font-size: 50px;text-align: center;line-height: 195%;">转场中请等待</div>
            <div v-else-if="stagelivePartNumber==1" class="nowjd">
              <div> 路演</div>
              <div :class="{ 'toRed': min== 0&&sec<=10}"> {{ time }} </div> 
            </div>
            <div v-else-if="stagelivePartNumber==2" class="nowjd">
              <div> 答辩</div>
              <div :class="{ 'toRed': min== 0&&sec<=10}"> {{ time }} </div> 
            </div>
            <div v-else-if="stagelivePartNumber==4" class="nowjd">
              <div> 得分</div>
              <div> {{bjcurrentdata.score}}</div> 
            </div>
            <div class="nowps" v-else-if="stagelivePartNumber==3">
              <div v-if="config1.data.length<6" style="display:flex;padding:0 10%;width: 80%;">
                <div style="position: relative;width: 20%;" v-for="(x,index) in currentJudges.slice(0,5)" :key="index*9">
                        <img :src="getImgUrl(x.stagejudgeStatus)" alt="" class="animate__animated" :class="{animate__flash : x.stagejudgeStatus}"> 
                         <div style="margin-left: 5%;">{{x.judgerName}} </div>
                      </div>
                <div v-if="config1.data.length%5" style="position: relative;width: 20%;" v-for="i in 5-(config1.data.length%5)" :key="i*1000">
                        <!-- 用于补充 -->
                  </div>
              </div> 
              <el-carousel v-else trigger="click" height="100%" :interval=4000>
                  <el-carousel-item v-for="item in parseInt(config1.data.length/5)" :key="item">
                    <div style="display:flex;padding-left: 10%;width: 80%;">
                      <div style="position: relative;width: 20%;" v-for="(x,index) in currentJudges.slice((item-1)*item,5*item)" :key="index*2.6">
                        <img :src="getImgUrl(x.stagejudgeStatus)" alt="" class="animate__animated" :class="{animate__flash : x.stagejudgeStatus}"> 
                        <div style="margin-left: 5%;">{{x.judgerName}} </div>
                      </div>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item v-if="(currentJudges.length%5)">
                    <div style="display:flex;padding-left: 10%;width: 80%;">
                      <div style="position: relative;width: 20%;" v-for="item in currentJudges.slice(-(config1.data.length%5),currentJudges.length)" :key="item.stagejudgeId">
                        <img :src="getImgUrl(item.stagejudgeStatus)" alt=""  class="animate__animated" :class="{animate__flash : item.stagejudgeStatus}" > 
                        <div style="margin-left: 5%;">{{item.judgerName}} </div>
                      </div>
                      <div style="position: relative;width: 20%;" v-for="i in 5-(config1.data.length%5)" :key="i*101">
                        <!-- 用于补充 -->
                      </div>
                    </div>
                  </el-carousel-item>
              </el-carousel>
            </div>
           
            <div class="boxfoot"></div>
          </div>

          <div class="ak">
            <div class="pstitle">
              <!-- <div class="psborder"></div>
              <div style="padding-left: 10px">评委 列表</div> -->
              <div class="table-header" style="width:100%">
                <span style="flex-grow: 1; text-align: center;">序号</span>
                <span style="flex-grow: 1; text-align: center;">评委</span>
                <span style="flex-grow: 1 ; text-align: center">状态</span>
                <span style="flex-grow: 1; text-align: center;">角色</span>
              </div>
            </div>
            <dv-scroll-board
              :config="config1"
              :style="style2"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import '../assets/iconfont.css'
var timer1; //这是实时获取数据的定时器
var timer2; //路演答辩倒计时定时器
export default {
  data() {
    return {
      sw:0,         //search的宽度
      w:0,         //子元素的宽度和
      introduceH:'',  //inrroduxe的高度
      style1:'',
      style2:'',
      min: 0,      //倒计时分钟
      sec: 0,       //倒计时秒针
      time: "",    //用来显示倒计时的时间
      timer: "",   //定时器
      status:0,    //判断是否倒计时  1为开启
      leftSec:0,    //用来判断有没有复位

      config: {},  //项目列表
      config1: {},   // 评委列表
      classify: [],  //分组列表
      active: "",    //分组按钮是否被选中
      isShow: false,  //是否展示过多的分组
      isAnimate:false,  // 是否启用翻滚动画


      //---请求所需数据
      stageliveId:'', //赛事现场id
      stageId:'',     //环节id
      groupId:'',  //分组id
      contestId:'',  //赛事id
      stageName:'',  // 大赛名
      currentProjectname:'',  //当前在评项目的名字 
      stagelivePartNumber:0,  // 当前再评项目的阶段 默认开始为0：转场等待
      currentJudges:[],
      contprojRegion:'', // 项目地区名

      bjcurrentdata:'',      //用于比较的data，实时获取到的数据，与之前的数据没有变化的话就不重新渲染
      bjjudgerdata:'',
      bjprojectdata:'',

    };
  },
  mounted() {
    // //获取组别信息，和大赛信息
    this.getGroupData();
  },
  methods: {
    //倒计时
    timedown() {
      if (this.min <= 0 && this.sec <= 0) {
        return
      }
      if (this.sec > 0) {
        this.sec--;
      } else {
        this.min--;
        this.sec = 59;
      }
      this.time =
        (this.min >= 10 ? this.min : "0" + this.min) +
        ":" +
        (this.sec >= 10 ? this.sec : "0" + this.sec);
    },
    
    //分组按钮选中事件
    selected(name) {
      this.active = name; //高光
      this.getgroupId(name)
      console.log(this.classify)
      this.FirstgetGroupProjectData() //获取相应组的项目信息
    },
    //下拉菜单按钮切换
    morebt() {
      this.isShow = !this.isShow;
    },

    //弹出错误提示
    openError(text) {
        this.$message.error(text);
    },
    //切换评审头像
    getImgUrl(stagejudgeStatus){
        if(stagejudgeStatus==0){
          return require('../assets/touping-people.png')
        }
        else{
          return require('../assets/touping-gou.png')
        }
    },
    //----------向后台发请求

    //获取组别信息，和大赛信息
		getGroupData() {
				console.log('getGroupdata');
				this.$post("/api/stagelive/getLiveGroupData", {
					stageliveId:this.$route.query.stageliveId,
				}).then((res) => {
					console.log(res.resultData);
          if(!res.result){ //失败  返回错误信息
            this.openError(res.resultMsg)
            clearInterval(timer1)
            return
          }
          else{
            this.classify=res.resultData.groupList
            this.active=res.resultData.groupName
            this.groupId=res.resultData.groupId
            this.stageName=res.resultData.contest.contestName
            this.stageId=res.resultData.stage.stageId
            this.contestId=res.resultData.contest.contestId
            this.FirstgetGroupProjectData()
          }
					
				}).catch((res) => {
					console.log(res);
				})
			},
      //首次获取对应组别的 项目信息  
		FirstgetGroupProjectData() {
        console.log(this.contestId)
        console.log(this.stageId)
        console.log(this.groupId)
        var that = this;
				console.log('getGroupProjectData');
				this.$post("/api/stagelive/getLiveGroupProjectData", {
					contestId:this.contestId,
          stageId:this.stageId,
          groupId:this.groupId,  
				}).then((res) => {
          if(!res.result){  //失败 返回错误信息
            clearInterval(timer1)
            this.changeprojectList({})
            this.changejudgerList({})
            this.getcurrentProject({})
            this.openError(res.resultMsg)
            return
          }
          else{
            this.changeprojectList(res.resultData.projectList)
            this.changejudgerList(res.resultData.judgerList)
            this.getcurrentProject(res.resultData.currentProject)
            this.bjprojectdata=res.resultData.projectList
            this.bjjudgerdata=res.resultData.judgerList
            this.bjcurrentdata=res.resultData.currentProject
            this.contprojRegion=res.resultData.currentProject.contprojRegion
            console.log(this.contprojRegion)
            if(res.resultData.currentProject.leftSec>0){this.leftSec=res.resultData.currentProject.leftSec}
            clearInterval(timer1);
            timer1 = setInterval(function() {
              // console.log(that.bjcurrentdata!=res.resultData.currentProject)
              that.NextTimeget()
              // console.log(this.isAnimate)
            }, 1000);
            //根据子元素的宽度判断分组按钮有没有换行
             this.w=0
              this.sw=this.$refs.navUl.clientWidth
              this.$refs.navUl.children.forEach(v=>{
              this.w=this.w+v.clientWidth+parseInt(getComputedStyle(v).getPropertyValue('margin-right'))
              })
              console.log(this.w)
              console.log(this.sw)
              //判断class：introduce 的高度给表格进行不同的高度 
              this.introduceH=this.$refs.introduce.clientHeight
              console.log(this.introduceH)
              if(this.introduceH>65){
                this.style1="width: 100%; height: 60%" 
                this.style2="width: 100%; height: 80%"
              }
              else{
                this.style2="width: 100%; height: 87%"
                this.style1="width: 100%; height: 64%"
              }

              //答辩或者路演的时候
            if(res.resultData.currentProject.stagelivePartNumber==1 || res.resultData.currentProject.stagelivePartNumber==2){
              //时间复位
              if(res.resultData.currentProject.status==0 && res.resultData.currentProject.startTime==""){
                  that.getcurrentProject(res.resultData.currentProject)
                  if(timer2){
                    clearInterval(timer2);
                    timer2=null;
                  }
                  return
              }else if(res.resultData.currentProject.status==1 && (this.min>0 || this.sec>0)){ //时间开始
                that.timedown()
                if(!timer2){
                  timer2 = setInterval(function() {
                    that.timedown()
                  }, 1000);
                }
              }else{ //时间暂停
                var minutes = parseInt(res.resultData.currentProject.leftSec/60)<10 ? "0"+parseInt(res.resultData.currentProject.leftSec/60):parseInt(res.resultData.currentProject.leftSec/60);
                var second = (res.resultData.currentProject.leftSec%60)<10 ? "0"+(res.resultData.currentProject.leftSec%60):(res.resultData.currentProject.leftSec%60);
                this.time = minutes+":"+second;
                if(timer2){
                  clearInterval(timer2);
                  timer2=null;
                }
              }
            }
          }	
				}).catch((res) => {
					console.log(res);
				})
			},
      //实时获取数据
    NextTimeget(){
      // console.log(Object.prototype.toString.call(this.classify))
      var that = this;
				this.$post("/api/stagelive/getLiveGroupProjectData", {
					contestId:this.contestId,
          stageId:this.stageId,
          groupId:this.groupId,
				}).then((res) => {
					console.log(res);
          if(!res.result){  //失败 返回错误信息
            this.openError(res.resultMsg)
            // clearInterval(timer1)
            // return
          }
          else{
            
            //当前再评栏的状态是否改变
            if(that.bjcurrentdata.name==res.resultData.currentProject.name
              &&that.bjcurrentdata.stagelivePartNumber==res.resultData.currentProject.stagelivePartNumber){}
            else{ console.log("在评项目数据改变") 
                this.isAnimate=true  //反转动画
                this.contprojRegion=res.resultData.currentProject.contprojRegion
                that.getcurrentProject(res.resultData.currentProject)
                this.bjcurrentdata=res.resultData.currentProject  //更新比较数据 
            }
            //答辩或者路演的时候
            if(res.resultData.currentProject.stagelivePartNumber==1 || res.resultData.currentProject.stagelivePartNumber==2){
              //时间复位
              if(res.resultData.currentProject.status==0 && res.resultData.currentProject.startTime==""){
                  that.getcurrentProject(res.resultData.currentProject)
                  if(timer2){
                    clearInterval(timer2);
                    timer2=null;
                  }
              }else if(res.resultData.currentProject.status==1 && (this.min>0 || this.sec>0)){ //时间开始
                if(!timer2){
                  that.timedown()
                  timer2 = setInterval(function() {
                    that.timedown()
                  }, 1000);
                }
              }else{ //时间暂停
                var minutes = parseInt(res.resultData.currentProject.leftSec/60)<10 ? "0"+parseInt(res.resultData.currentProject.leftSec/60):parseInt(res.resultData.currentProject.leftSec/60);
                var second = (res.resultData.currentProject.leftSec%60)<10 ? "0"+(res.resultData.currentProject.leftSec%60):(res.resultData.currentProject.leftSec%60);
                this.time = minutes+":"+second;
                if(timer2){
                  clearInterval(timer2);
                  timer2=null;
                }
              }
            }
            
            //只有一个赛事现场情况下，根据当前项目切换分组
            if(res.resultData.liveSign==1 && res.resultData.currentProject.groupId!=null && res.resultData.currentProject.groupId!=this.groupId){
              for(let i=0;i<this.classify.length;i++){
                if(res.resultData.currentProject.groupId==this.classify[i].stagegroupId){ this.selected(this.classify[i].stagegroupName); }
              }
            }

            //评审评分的状态是否改变
            if(res.resultData.currentProject.stageName== "评审"){
              for(let j=0;j<that.bjcurrentdata.currentJudges.length;j++){
                if(that.bjcurrentdata.currentJudges[j].stagejudgeStatus==res.resultData.currentProject.currentJudges[j].stagejudgeStatus){}
                else{ console.log("评审状态数据改变")
                   that.getcurrentProject(res.resultData.currentProject)
                   this.bjcurrentdata=res.resultData.currentProject  //更新比较数据 
                  }
              }
            }

            //判断项目列表的数据有没有发送变化
            for(let j=0;j<that.bjprojectdata.length;j++){
                if(that.bjprojectdata[j].score!=res.resultData.projectList[j].score){
                    console.log("项目列表数据改变")
                    that.changeprojectList(res.resultData.projectList)
                    this.bjprojectdata=res.resultData.projectList  //更新比较数据
                    break
                }
            }
            for(let j=0;j<that.bjjudgerdata.length;j++){
                if(that.bjjudgerdata[j].loginStatus==res.resultData.judgerList[j].loginStatus
                || that.bjjudgerdata[j].judgerLeader==res.resultData.judgerList[j].judgerLeaderp
                ){}
                else{ console.log("评委列表数据改变")
                that.changejudgerList(res.resultData.judgerList)
                this.bjjudgerdata=res.resultData.judgerList //更新比较数据
                }
              
            }
           
          }	
				}).catch((res) => {
					console.log(res);
				})
    },
    //把获取到的 项目数据 变成我想要的格式
    changeprojectList(data){
       console.log('获取到项目')
      let arr=[]
      let str=''
      let color=''
      let bfb=0
      let score=''
      let jbeicolor=['gold','silver','darkgoldenrod']
      for(let i=0;i<data.length;i++){
        let jbei=`<span class='iconfont icon-jiangbei' style="color:`+jbeicolor[i]+`"></span>`
        if(data[i].stageprojScoreStatus>0 && data[i].score!=null ){ str='已评'; color='yellow'; bfb=data[i].score}
        else{str='未评'; color='';bfb=0}
        if(data[i].stageprojScoreStatus==0 || data[i].score==null){score='--'}
        else{score=data[i].score} 
        if(i>2 || str=='未评'){jbei=''} 
        arr.push(
          [
            '<div style="font-size:20px; font-family: ' +"Times New Roman" +', Times, serif">' + "No." +(i+1)+" "+ jbei+ "</div>",
            '<div style="font-size:18px;margin: 0;padding: 0;text-align: center;text-overflow: ellipsis;overflow: hidden;">'+data[i].contprojName+'</div>',
            `<div style="color:`+color+`;font-size: 20px;  text-align: center; font-weight: bold;">`+str+`</div>`,
            `<div style="font-size:22px;color:`+color+` ;text-align: center;" >`+score+ `</div>
              <!--<div class="jdbar" style=" position: relative;top:-55%;width:30%;left: -3%;height: 5px; background-color: #bbb;border-radius: 15px;">
               <div style="width: `+bfb+ `%;height: 100%; background-color: gold;border-radius: 15px">
              </div>--> 
            `,
          ]
        )
      }
      this.config = {
        data: arr,
        columnWidth: [130,400],
        rowNum: 9,
        headerBGC: "#37A2FB",
        oddRowBGC: "#01195B",
        evenRowBGC: "#012372",
        align: ["center"],
        waitTime: 2000,
      };
    },
    //把获取到的 评委数据 变成我想要的格式
    changejudgerList(data){
      console.log('获取到评委')
      let arr=[]
      let str1='';let str2=''
      let bgcolor=['#ff0305',' #ffb20c',' #18d0c6',' #00b4ff',' #6c3ff2']
      let color=''
      for(let i=0;i<data.length;i++){
        if(data[i].loginStatus>0){ str1='已登录'; color='yellow'}
        else{str1='未登录'; color=''}
        if(data[i].judgerLeader>0){str2=`<i class="el-icon-user-solid"></i> 组长`;}
        else{str2='组员';}
        arr.push(
           [
            `<span style="background-color: `+bgcolor[i%5]+`;display: inline-block;width: 25px;font-size: 20px;
             height: 25px;vertical-align: middle;text-align: center;line-height: 20px;">`+(i+1)+`</span>`,
            `<div style="font-size: 20px;text-align: center">`+data[i].judgerName+`</div>`,
             "<div style='font-size: 20px;font-weight: bold;color:"+color+";text-align: center;'>"+str1 +"</div>",
            `<div style="font-size: 20px;text-align: center;">`+str2+`</div>`,
          ],
        )
      }
      this.config1 = {
        data: arr,
        columnWidth: [],
        rowNum: 5,
        headerBGC: "#37A2FB",
        oddRowBGC: "#01195B",
        evenRowBGC: "#012372",
        align: ["center"],
        waitTime: 2000,
      };
    },
    //当前在评项目数据
    getcurrentProject(data){
        this.currentProjectname=data.name
        this.stagelivePartNumber=data.stagelivePartNumber
        this.currentJudges=data.currentJudges
        this.min=parseInt(data.leftSec/60)
        this.sec=data.leftSec%60
        this.time =
        (this.min >= 10 ? this.min : "0" + this.min) +
        ":" +
        (this.sec >= 10 ? this.sec : "0" + this.sec);
    },
    //获取项目分组id
    getgroupId(name){
      for(let i=0;i<this.classify.length;i++){
        if(name==this.classify[i].stagegroupName){ this.groupId=this.classify[i].stagegroupId}
      }
      if(this.isShow){this.reSort(this.groupId)}
    },
    //项目分组重排列
    reSort(id){
      console.log(id)
      console.log(this.classify)
      var b=[]
      for(let i=0;i<this.classify.length;i++){
          // console.log(id!=this.classify[i].stagegroupId)
         if(id!=this.classify[i].stagegroupId){ 
          b.push(this.classify[i])
         }
         else{
          break
         }
      }
      // console.log(b)
      for(let j=0;j<b.length;j++){
        this.classify.shift()
        this.classify.push(b[j])
      }
       this.isShow=false
    }
   
  },
  watch:{
        // 被侦听的变量stagelivePartNumber
        stagelivePartNumber(){
          setTimeout(() => {
               this.isAnimate=false
               console.log(this.isAnimate)
            }, 1200)               
        },
        currentProjectname(){
          setTimeout(() => {
               this.isAnimate=false
               console.log(this.isAnimate)
            }, 1200)               
        }
  },
  beforeDestroy() {
    clearInterval(timer1);
    clearInterval(timer2);
  }

};
</script>


<style scoped>
#index {
  color: #e5ebf8;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  overflow: hidden;
}
.bg {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #00154f;
  background-image: url(../assets/bg\ \(1\).jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
}

.bg div,.bg span{
  font-size: 22px
}
.top {
  width: 100%;
  display: flex;
}
.title {
  width: 50%;
  position: absolute;
  top: 3.5%;
  left: 25%;
  display: flex;
  justify-content: center;
  font-size: xx-large;
}
.title .title-text {
  color: rgb(255, 255, 255);
  text-shadow: rgb(0, 216, 255) 0px 0px 25px;
  font-size: 46px;
  font-family: "Adobe 楷体 Std R";
}
.search {
  display: flex;
  margin: 5% 4% 0px;
  align-items: center;
  align-items: baseline;
  flex-wrap: wrap;
  height: 5%;
  overflow: hidden;
}
.search div{
  margin-right: 2%;
}
.toHeight{
    overflow: unset;
     height:auto; 
}
.compute {
  background-color: #072d84;
  cursor: pointer;
  padding: 0 10px;
  height: 30px;
  text-align: center;
  border-radius: 5px;
  line-height: 30px;
  font-size: smaller;
  border: 1px solid floralwhite;
  margin-bottom: 1.5%;
}
.introduce {
  position: relative;
  display: flex;
  width: 92%;
  /* height: 65px; */
  background-color: #01205e;
  color: #01e1f2;
  margin: 1.1% 4% 2%;
  flex-wrap: wrap;
  /* overflow: hidden; */
  text-overflow: ellipsis
}
.border {
  position: absolute;
  height: 100%;
  background-color: #5cbee8;
  width: 5px;
  border-radius: 5px 0 0 5px;
  margin-right: 3px;
}
.chart {
  display: flex;
  margin-left: 26px;
  height: 100%;
}
.left {
  display: flex;
  flex-direction: column;
  width: 36%;
  height: 64%;
  margin-left: 2%;
}
.right {
  margin-left: 50px;
  width: 55%;
  height: 90%;
}
.right .table-header {
  display: flex;
  height: 5%;
  background-color: #072d84;
  border: 1px solid #5cbee8;
  align-items: center;
  /* justify-content: center; */
  border-radius: 5px 5px 0 0;
}
.pstitle .table-header{
  display: flex;
  height: 5%;
  background-color: #072d84;
  border: 1px solid #5cbee8;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 0 0;
}
.ak {
  margin-top: 4%;
  width: 100%;
  height: 50%;
  /* background-color: #fff; */
}
.ak47 {
  display: flex;
  margin-bottom: 35px;
}
.ak .ak-left,
.ak .ak-right {
  width: 25px;
  height: 25px;
  /* background-color: #c5f26b; */
  border: 0.1px solid white;
}
.ak .ak-right {
  background-color: tan;
  
}
.ak .ak-cerent {
  width: 300px;
  height: 25px;
}
.jdbar {
  width: 80px;
  height: 5px;
  background-color: #fff;
  border-radius: 15px;
}
.inner {
  width: 50%;
  height: 100%;
  background-color: gold;
  border-radius: 15px;
}
.now {
  width: 400px;
  height: 220px;
  border: 1px solid #5cbee8;
  padding: 0;
  border-radius: 5px;
}
.top_bottom .xmname {
    width: 100%;
    display: block;
    text-align: center;
    padding: 0px 25px;
    font-size: 32px;
    font-family: serif;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
   
}
.nowping {
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 39px;
  background-color: #134291c2;
}
.nowjd {
  width: 100%;
  height: 40%;
  display: flex;
  padding: 0;
  justify-content: center
}
.nowjd div {
  width: 40%;
  text-align: center;
  line-height: 185%;
  font-size: 63px;
}
.nowjd div:nth-child(1){
  font-size: 58px;
}
.nowps{
  width: 100%;
  height: 40%;
  display: flex;
  padding: 0;
  /* justify-content: center */
}
.nowps div { 
  /* height: 90%; */
  flex-grow: 3;
  text-align: center;
}
.nowps div img{ 
    width: 40%;
    border: 0;
    border-radius: 50%;
    margin-left: 5%;
}


.pstitle {
  display: flex;
  background-color: #181b7c;
  /* color: #01e1f2; */
}
.pstitle .psborder {
  background-color: #5cbee8;
  width: 5px;
  border-radius: 5px 0 0 0px;
  margin-right: 3px;
}

.top_bottom {
  width: 100%;
  height: 40%;
  /* background-image: url(../assets/o.webp); */
  background-color: rgb(6,9,51);
  /* background-position: left; */
  border: 1px solid #20558b;
  position: relative;
  margin-bottom: 15px;
  margin-top: 10px;
}

.min-title {
  width: 200px;
  height: 40px;
  background: #072d84;
  /*margin: 0 auto;*/
  top: -20px;
  position: absolute;
  left: 47%;
  border-radius: 20px;
  text-align: center;
  margin-left: -90px;
}
.min-title span {
  display: inline-block;
  line-height: 40px;
  color: white;
}

/*四个角的*/
.public:before,
.public:after {
  position: absolute;
  width: 20px;
  height: 20px;
  content: "";
  border-top: 2px solid #02a6b5;
  top: 0;
}
.public:before,
.boxfoot:before {
  border-left: 2px solid #02a6b5;
  left: 0;
}
.public:after,
.boxfoot:after {
  border-right: 2px solid #02a6b5;
  right: 0;
}

.boxfoot {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.boxfoot:before,
.boxfoot:after {
  position: absolute;
  width: 20px;
  height: 20px;
  content: "";
  border-bottom: 2px solid #02a6b5;
  bottom: 0;
}
/*四个角的*/

.active {
  background-color: #3a8ee6;
}

.more {
  display: flex;
  flex-direction: column;
  margin: 0 68px;
}
.morecontent {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  /* height: 0; */
}
.morecontent .morerow{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.morecontent .lastrow{
  width: 100%;
  display: flex;
}
.morecontent .lastrow div{
  margin-right: 2%;
}
.morebt {
  margin: 2px;
  text-align: center;
}
.morebt i {
  font-size: xx-large;
  width: 50px;
  border-radius: 15px;
}

.morebt i:hover {
  background-color: mediumblue;
  cursor: pointer;
}
.show {
  height: 100%;
}
.toRed{
  color: red;
}
.changeheight1{
  height: 60%;
}
.changeheight2{
  height: 80%;
}
</style>
